.ldsEllipsis,
.ldsEllipsis div {
    box-sizing: border-box;
}

.ldsEllipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 20px;
    transform: translateY(-27px);
}

.ldsEllipsis div {
    position: absolute;
    top: 33.33333px;
    width: 13.33333px;
    height: 13.33333px;
    border-radius: 50%;
    background: currentColor;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ldsEllipsis div:nth-child(1) {
    left: 8px;
    animation: ldsEllipsis1 0.6s infinite;
}

.ldsEllipsis div:nth-child(2) {
    left: 8px;
    animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(3) {
    left: 32px;
    animation: ldsEllipsis2 0.6s infinite;
}

.ldsEllipsis div:nth-child(4) {
    left: 56px;
    animation: ldsEllipsis3 0.6s infinite;
}

@keyframes ldsEllipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes ldsEllipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes ldsEllipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}